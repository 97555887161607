import { ColumnDef } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import { Item } from '~/shared/api/services/delivery/types'
import { Checkbox } from '~/shared/ui'
import { SVGNOtFoto } from '~/shared/ui/SVG/SVGNotFoto'

const CheckboxAnHonestSign = observer(() => {
  return (
    <Checkbox
      //   onClick={() => handleClick()}
      checked={false}
    />
  )
})

export const DELIVERY_TABLE_CONFIG: ColumnDef<Item>[] = [
  {
    header: 'anHonestSign',
    accessorKey: 'anHonestSign',

    cell: () => {
      return (
        <div className="ml-14">
          <CheckboxAnHonestSign />
        </div>
      )
    },
    maxSize: 100,
  },
  {
    header: 'manufacturer',
    accessorKey: 'manufacturer',

    cell: (row) => {
      const { original } = row.cell.row

      return <div> {original.brandName}</div>
    },
    maxSize: 100,
  },
  {
    header: 'Product name',
    accessorKey: 'Product name',

    cell: (row) => {
      const { original } = row.cell.row

      return <div> {original.productName}</div>
    },
    maxSize: 150,
  },

  {
    header: 'options',
    accessorKey: 'options',

    cell: (row) => {
      return (
        <div>
          {' '}
          {row.cell.row.original.options.text.split('<br>').map((el) => (
            <div key={el}>{el}</div>
          ))}
        </div>
      )
    },
    maxSize: 150,
  },

  {
    header: 'image',
    accessorKey: 'image',

    cell: (row) => {
      const { original } = row.cell.row

      const imageSrc = original?.image

      return (
        <div className="w-[150px] h-[150px]  rounded shadow">
          {imageSrc ? (
            <img
              className="w-full h-full  rounded"
              src={imageSrc}
              alt="Product Thumbnail"
            />
          ) : (
            <div className="w-full h-full rounded">
              <SVGNOtFoto size="64" />
            </div>
          )}
        </div>
      )
    },
    maxSize: 150,
  },
]
