import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { useStore } from '~/app/store'
import { Card, Switcher, TableContainer } from '~/shared/ui'

import { DELIVERY_TABLE_CONFIG } from './lib/configTable'
import { Input } from '~/shared/ui/input'

export const OrdersItemsTable = observer(() => {
  const { deliveryStore } = useStore()
  const { itemOrderById, loaders } = deliveryStore
  const { t } = useTranslation()
  const barcodeInputRef = useRef<HTMLInputElement>(null)
  const [barcodeMode, setBarcodeMode] = useState(false)

  useEffect(() => {
    if (barcodeMode) {
      setTimeout(() => {
        if (barcodeInputRef.current) {
          barcodeInputRef.current.focus()
        }
      }, 0)
    }
  }, [barcodeMode])

  return (
    <Card>
      <div className=" flex flex-col gap-3 w-[50%]  p-4">
        <div className=" py-3 w-[50%] flex ">
          <Switcher
            onChange={(value) => {
              setBarcodeMode(value)
              //   setFormData('barcode', '')
            }}
          />
          <p> {t('searchByBarcode')}</p>
        </div>
        {barcodeMode && (
          <form
            className="flex items-center gap-3 w-[40%]"
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <Input
              labelType="floating"
              label="Штрихкод"
              className="rounded-lg"
              id="barcode"
              autoFocus
              ref={barcodeInputRef}
            />
          </form>
        )}
      </div>
      <div>
        <TableContainer
          fixedWidth
          isLoading={loaders.shippingOrderById}
          columns={DELIVERY_TABLE_CONFIG}
          data={itemOrderById}
        />
      </div>
    </Card>
  )
})
