/* eslint-disable react/require-default-props */
interface PropsNotFoto {
  size?: string
  color?: string
}

export const SVGPrint = ({ size, color }: PropsNotFoto) => {
  return (
    <svg
      width={size || '24'}
      height={size || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="print"
        d="M16 8.3L16 5.3L8 5.3L8 8.3L6.5 8.3L6.5 4.7C6.5 4.45 6.58 4.23 6.76 4.06C6.93 3.89 7.14 3.8 7.4 3.8L16.59 3.8C16.85 3.8 17.06 3.89 17.24 4.06C17.41 4.23 17.5 4.45 17.5 4.7L17.5 8.3L16 8.3ZM17.8 12.3C18.09 12.3 18.32 12.21 18.52 12.02C18.71 11.82 18.8 11.59 18.8 11.3C18.8 11.02 18.71 10.78 18.52 10.59C18.32 10.4 18.09 10.3 17.8 10.3C17.52 10.3 17.28 10.4 17.09 10.59C16.9 10.78 16.8 11.02 16.8 11.3C16.8 11.59 16.9 11.82 17.09 12.02C17.28 12.21 17.52 12.3 17.8 12.3ZM8.3 19L15.69 19C15.78 19 15.85 18.97 15.91 18.91C15.97 18.85 16 18.78 16 18.69L16 14.73L8 14.73L8 18.69C8 18.78 8.02 18.85 8.08 18.91C8.14 18.97 8.21 19 8.3 19ZM8.3 20.49C7.81 20.49 7.38 20.32 7.03 19.96C6.67 19.61 6.5 19.18 6.5 18.69L6.5 16.49L3.69 16.49C3.43 16.49 3.22 16.41 3.04 16.24C2.87 16.06 2.78 15.85 2.78 15.59L2.78 10.8C2.78 10.09 3.03 9.5 3.51 9.02C3.99 8.54 4.58 8.3 5.28 8.3L18.71 8.3C19.41 8.3 20.01 8.54 20.49 9.02C20.97 9.5 21.21 10.09 21.21 10.8L21.21 15.59C21.21 15.85 21.12 16.06 20.95 16.24C20.77 16.41 20.56 16.49 20.3 16.49L17.5 16.49L17.5 18.69C17.5 19.18 17.32 19.61 16.96 19.96C16.61 20.32 16.18 20.49 15.69 20.49L8.3 20.49ZM19.71 15L19.71 10.8C19.71 10.52 19.61 10.28 19.42 10.09C19.23 9.9 18.99 9.8 18.71 9.8L5.28 9.8C5 9.8 4.76 9.9 4.57 10.09C4.38 10.28 4.28 10.52 4.28 10.8L4.28 15L6.5 15L6.5 13.23L17.5 13.23L17.5 15L19.71 15Z"
        fill={color || 'gray'}
      />
    </svg>
  )
}
