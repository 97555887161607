import { observer } from 'mobx-react-lite'
import { Card, Switcher } from '~/shared/ui'
import { Input } from '~/shared/ui/input'

export const PrintedForm = observer(() => {
  return (
    <div className="flex justify-between gap-3">
      <Card className="w-[50%] p-6">
        <div className=" flex flex-col items-center justify-center gap-4  ">
          <div className="w-full flex flex-col gap-2">
            <p className="relative">
              Полное имя
              <span className="absolute bottom-2.5 left-[92px] text-red-500 text-[8px]">
                ●
              </span>
            </p>
            <Input className="rounded-xl" labelType="floating" label="Имя" />
          </div>
          <div className="w-full flex flex-col gap-2">
            <p className="relative">
              Индекс
              <span className="absolute bottom-2.5 left-[58px] text-red-500 text-[8px]">
                ●
              </span>
            </p>
            <Input className="rounded-xl" labelType="floating" label="Индекс" />
          </div>
          <div className="w-full flex flex-col gap-2">
            <p className="relative">
              Город
              <span className="absolute bottom-2.5 left-[46px] text-red-500 text-[8px]">
                ●
              </span>
            </p>
            <Input className="rounded-xl" labelType="floating" label="Город" />
          </div>
          <div className="w-full flex flex-col gap-2">
            <p>Регион</p>
            <Input className="rounded-xl" labelType="floating" label="Регион" />
          </div>
          <div className="w-full flex flex-col gap-2">
            <p>Страна</p>
            <Input className="rounded-xl" labelType="floating" label="Страна" />
          </div>
          <div className="w-full flex flex-col gap-2">
            <p className="relative">
              Адрес
              <span className="absolute bottom-2.5 left-[48.5px] text-red-500 text-[8px]">
                ●
              </span>
            </p>
            <Input className="rounded-xl" labelType="floating" label="Адрес" />
          </div>
          <div className="w-full flex flex-col gap-2">
            <p className="relative">
              Телефон
              <span className="absolute bottom-2.5 left-[66.5px] text-red-500 text-[8px]">
                ●
              </span>
            </p>
            <Input
              className="rounded-xl"
              labelType="floating"
              label="Телефон"
            />
          </div>
          <div className="w-full flex flex-col gap-2">
            <p>Электронная почта</p>
            <Input
              className="rounded-xl"
              disabled
              intent="disabled"
              labelType="floating"
              label="Электронная почта"
            />
          </div>
          <div className="w-full flex flex-col gap-2">
            <p>Компания</p>
            <Input
              className="rounded-xl"
              labelType="floating"
              label="Компания"
            />
          </div>
        </div>
      </Card>

      <Card className="w-[50%] h-[350px]  p-6">
        <div className="pb-6 flex flex-col gap-2">
          <p className="text-[12.5px] font-medium">DAP / DDP</p>
          <Switcher label="Активна" />
        </div>
        <div className="flex flex-col gap-3 ">
          <div className="w-full flex flex-col gap-3">
            <p className="relative">
              Вес
              <span className="absolute bottom-2.5 left-[30px] text-red-500 text-[8px]">
                ●
              </span>
            </p>
            <Input className="rounded-xl" labelType="floating" label="Вес" />
            <p className="text-[#707070] text-[12.5px]">
              Вес отправления в граммах
            </p>
          </div>
          <div className="w-full flex flex-col gap-3">
            <p>Стоимость заказа</p>
            <Input
              className="rounded-xl"
              labelType="floating"
              label="  Стоимость заказа"
            />
            <p className="text-[#707070] text-[12.5px]">
              Полная стоимость заказа в рублях
            </p>
          </div>
        </div>
      </Card>
    </div>
  )
})
