import { AxiosError } from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import { Loaders } from './types'
import { Dates, Item, OrderData } from '~/shared/api/services/delivery/types'
import { DeliveryService } from '~/shared/api/services/delivery'

export class DeliveryStore {
  loaders: Loaders = {
    shippingOrders: false,
    shippingOrderById: false,
  }

  shippingOrders: Dates | null = null

  shippingOrderById: OrderData | null = null

  itemOrderById: Item[] = []

  constructor() {
    makeAutoObservable(this)
  }

  activeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: true }
  }

  closeLoader = (key: keyof Loaders) => {
    this.loaders = { ...this.loaders, [key]: false }
  }

  getShippingOrders = async () => {
    this.activeLoader('shippingOrders')
    try {
      const { data } = await DeliveryService.getShippingOrders()
      this.shippingOrders = data.ordersData.dates
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('shippingOrders')
      })
    }
  }

  getShippingOrderById = async (id: string) => {
    this.activeLoader('shippingOrderById')
    try {
      const { data } = await DeliveryService.getShippingOrderById(id)
      this.shippingOrderById = data
      this.itemOrderById = data.items
      return data
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.config.data)
      }
    } finally {
      runInAction(() => {
        this.closeLoader('shippingOrderById')
      })
    }
  }
}

export default DeliveryStore
