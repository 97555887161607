/* eslint-disable no-nested-ternary */
import { Row, flexRender } from '@tanstack/react-table'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { TableBodyProps } from './types'
import { tableHead } from './config'
import { useStore } from '~/app/store'

export const TableBody = observer(
  <
    T extends Row<{
      demand: boolean
      id: string
      stock: number
      categoryId?: null | string
    }>,
  >({
    className,
    data,
    onRowClick,
    onRowContextMenu,
    hasSubRowContent,
    subRowComponent,
    cellsCount,
    alignBodyStyle,
  }: TableBodyProps<T>) => {
    const { ordersStore } = useStore()
    const hasData = data?.length > 0

    const SubRowComponent = subRowComponent

    return (
      <tbody
        className={`${tableHead({
          className,
        })} ${alignBodyStyle}`}
      >
        {!hasData && (
          <tr>
            <td
              colSpan={cellsCount}
              className="text-center text-[#707070] text-[12px] py-4 h-[250px]"
            >
              No data available
            </td>
          </tr>
        )}
        {hasData &&
          data.map((row, index) => {
            if (!row?.original) return null
            const typedRow = row.original as {
              demand?: boolean
              id: string
              categoryId?: null | string
              option?: boolean
              stock?: number
            }
            const isPermissions = row.original && 'permissions' in row.original
            const isNumberPresent = row.original && 'email' in row.original
            const dataCreated = row.original && 'created_at' in row.original
            const maxCountUse = row.original && 'maxCountUse' in row.original
            const isStatePresent = row.original && 'state' in row.original
            const minOrderPrice =
              row.original && 'minOrderPrice' in row.original
            const paddingValue = isStatePresent
              ? 'py-4'
              : minOrderPrice || isPermissions
              ? 'py-3 px-3'
              : isNumberPresent || dataCreated || maxCountUse
              ? 'px-1 py-4'
              : 'px-2 py-1'

            const theNumberOfNecessaryGoods =
              row.original && 'variationId' in row.original
            const rowID = typedRow.id

            const cells = row.getVisibleCells()
            return (
              <React.Fragment key={row.id}>
                <tr
                  onMouseEnter={() => {
                    ordersStore.setTempID(rowID)
                  }}
                  onMouseLeave={() => {
                    ordersStore.setTempID('')
                  }}
                  onClick={() => {
                    if (typedRow?.stock !== 0) {
                      onRowClick?.(row.original)
                    }
                  }}
                  onContextMenu={(e) => onRowContextMenu?.(e, row.original)}
                  className={`hover:bg-slate-100 transition-all ${
                    typedRow?.demand === true ? 'bg-[#FEF2F2]' : 'bg-white'
                  } ${
                    typedRow?.stock === 0
                      ? 'opacity-50 cursor-not-allowed'
                      : 'cursor-pointer'
                  }  ${
                    index === data.length - 1
                      ? 'border-none'
                      : 'border-t border-gray-200'
                  }  `}
                >
                  {cells.map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={`${paddingValue} ${
                          theNumberOfNecessaryGoods ? 'text-left' : ''
                        } text-sm text-gray-800 whitespace-nowrap`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    )
                  })}
                </tr>

                {hasSubRowContent && SubRowComponent ? (
                  <tr>
                    <td colSpan={cells.length}>
                      <SubRowComponent
                        id={rowID}
                        path={
                          typedRow.categoryId === null ? 'product' : 'category'
                        }
                      />
                    </td>
                  </tr>
                ) : null}

                {index !== data.length - 1 && (
                  <tr className={`${'border-t border-gray-200'}`} />
                )}
              </React.Fragment>
            )
          })}
      </tbody>
    )
  },
)
