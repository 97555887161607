import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '~/app/store'
import { Button, SVGEye } from '~/shared/ui'
import { SVGPrint } from '~/shared/ui/SVG/SVGPrint'
import { SVGDone } from '~/shared/ui/SVG/SVGDone'
import { DeliveryTabs } from '~/widgets/delivery/tabs-delivery'

export const ShippingOrderById = observer(() => {
  const { deliveryStore } = useStore()
  const { getShippingOrderById, shippingOrderById } = deliveryStore
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  useEffect(() => {
    if (id) {
      getShippingOrderById(id)
    }
  }, [getShippingOrderById, id])
  return (
    <>
      <div className="flex justify-between items-center pt-6 ">
        <div className="flex flex-col gap-3">
          <p className="text-[24px] text-[#242424]">
            Коробка {shippingOrderById?.orderDetails.box}. Проверка заказа{' '}
            {shippingOrderById?.orderDetails.number}
          </p>
          <p className="text-[14px] text-[#707070]">
            Вес: {shippingOrderById?.orderDetails.weight} гр. Обьемный:{' '}
            {shippingOrderById?.orderDetails.weightVolume} гр. Доставка:{' '}
            {shippingOrderById?.orderDetails.shippingMethod}
          </p>
          <p className="text-[14px] text-[#707070]">
            Пользователь: {shippingOrderById?.orderDetails.userName} собирал
            заказ
          </p>
        </div>

        <div className="flex h-[48px] gap-3">
          <Button onClick={() => navigate(`/orders/${id}/edit`)}>
            <SVGEye icon="visibility" color="white" />
            Просмотр заказа
          </Button>
          <Button>
            <SVGPrint color="white" /> Печать бланков
          </Button>
          <Button intent="success">
            <SVGDone color="white" size="30" /> Проверить
          </Button>
        </div>
      </div>
      <div>
        <DeliveryTabs />
      </div>
    </>
  )
})
