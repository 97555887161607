import { AxiosResponse } from 'axios'
import { apiService } from '../../api-base'
import { OrderData, OrdersData } from './types'

export class DeliveryService {
  static getShippingOrders(): Promise<AxiosResponse<OrdersData>> {
    return apiService.get<AxiosResponse<OrdersData>>('/shipping/orders')
  }

  static getShippingOrderById(id: string): Promise<AxiosResponse<OrderData>> {
    return apiService.get<AxiosResponse<OrderData>>(`/shipping/${id}`)
  }
}
